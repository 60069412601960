<template>
  <div
    class="yotpo-widget-instance"
    :data-yotpo-instance-id="yotpoStarsInstanceId"
    :data-yotpo-product-id="productId"
  ></div>
</template>

<script setup lang="ts">
import { useRootStore } from '@/store/index';

useYotpoRefresh();
const rootStore = useRootStore();
const yotpoStarsInstanceId = rootStore.yotpoStarsInstanceId;

defineProps({
  productId: {
    type: Number,
    required: true,
  },
});
</script>
